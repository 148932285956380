import React from "react"

import { useIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Error from "../components/error"

const NotFoundPage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Page Not Found" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <Error error="Page Not Found.Page Not Found" />
    </Layout>
  )
}

export default NotFoundPage
